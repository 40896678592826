import { Component, Vue, Prop, Watch, PropSync } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { Logger } from 'fsts';
import { Folder } from '@/shared/model/folder';
import { WorkflowRule } from '@/shared/model/workflowRule';
import folderRule, { FolderRule } from '@/shared/model/folderRule';
import { OdataItems } from '@/shared/model/OdataItems';
import { Organisation } from '@/shared/model/organisation';
import treeUtils from '@/shared/utils/treeUtils';

const name = 'action-copy-to-organization';
const logger = new Logger(name);
const organizationModule = namespace('organization');
const folderModule = namespace('folder');

@Component({
  name: name,
  components: {},
})
export default class ActionCopyToOrganization extends Vue {
  @PropSync('selected', {
    default: () => {
      return [];
    },
  })
  private selectedModel!: any[];
  @PropSync('value', { default: folderRule.parse({}) })
  private model!: FolderRule;
  @Watch('selectedModel', { deep: true })
  private onSelected(nVal: any, oVal: any) {
    if (nVal != oVal) this.menu = false;
  }
  @Watch('menu')
  onMenu(nVal: any, oVal: any) {
    this.setPattern();
  }
  setPattern() {
    if (!this.menu) {
      if (!this.selectedModel.length) {
        this.selectedModel = [this.pattern];
      }
      if (this.selectedModel.length) {
        this.model.actionMoveToFolderId = this.selectedModel[0].id;
        this.model.actionMoveToFolder = this.selectedModel[0].folder;
      }
      if (!this.model.actionMoveToFolderPattern) this.model.actionMoveToFolderPattern = '[Y]/[m]';
      if (!this.model.actionMoveToFolderPatternType && this.patternTypes[0])
        this.model.actionMoveToFolderPatternType = this.patternTypes[0].id;
    }
  }
  get options() {
    return [
      { id: 'copy_and_delete', name: this.$t('options.copy_types.copy_and_delete').toString() },
      { id: 'copy_and_keep', name: this.$t('options.copy_types.copy_and_keep').toString() },
    ];
  }
  copyType = 'copy_and_delete';
  items: {
    folder?: Folder;
    id: string;
    name: string;
    parent?: { name: string; id: string };
    children?: any[];
    fullPath: string;
  }[] = [];
  get pattern() {
    return {
      id: '',
      name: `${this.$t('pattern').toString()}`,
      parent: undefined,
      folder: undefined,
      children: undefined,
      fullPath: '',
    };
  }
  menu = false;
  getFullPath() {
    if (this.model.actionMoveToFolderId == '')
      return `${this.$t('pattern', { 0: this.model.actionMoveToFolderPattern }).toString()}`;
    return treeUtils.getFolderPath(this.model.actionMoveToFolderId, this.organizationFolders.treeViewFlat);
  }
  get patternTypes(): {
    id: 'document_upload' | 'latest_status' | 'latest_allocation' | 'invoice_date_of_latest_allocation';
    name: string;
  }[] {
    return [
      { id: 'document_upload', name: this.$t('pattern_types.document_upload').toString() },
      { id: 'latest_status', name: this.$t('pattern_types.latest_status').toString() },
      { id: 'latest_allocation', name: this.$t('pattern_types.latest_allocation').toString() },
      {
        id: 'invoice_date_of_latest_allocation',
        name: this.$t('pattern_types.invoice_date_of_latest_allocation').toString(),
      },
    ];
  }
  @organizationModule.Action('getOrganizationFoldersAll')
  private getOrganizationFolders!: any;
  @organizationModule.Getter('getOrganizationFoldersAll')
  private organizationFolders!: any;
  @organizationModule.Getter('getOrganizations')
  private organizations!: OdataItems<Organisation>;
  @organizationModule.Getter('getOrganization')
  private organization!: Organisation;
  get organizationsWithoutCurrent() {
    return this.organizations.items.filter((x) => x.id != this.organization.id);
  }
  @Watch('model.copyToOrganisationId')
  async onOrganization(newV: string, oldV: string) {
    if (newV) {
      this.refreshFolders();
    }
  }
  get haveModelId() {
    return this.selectedModel[0] && !this.selectedModel[0].id;
  }
  async mounted() {
    this.AutoselectFirstCompany();

    if (this.model.copyToOrganisationId) {
      this.refreshFolders();
    }
    this.setPattern();
  }
  AutoselectFirstCompany() {
    if (!this.model.copyToOrganisationId && this.organizationsWithoutCurrent.length > 0) {
      this.model.copyToOrganisationId = this.organizationsWithoutCurrent[0].id;
    }
  }
  private async refreshFolders() {
    const organizationId = this.model.copyToOrganisationId;
    await this.getOrganizationFolders({ organizationId });
    this.items.length = 0;
    this.items.push(this.pattern);
    await this.organizationFolders?.treeView.forEach((element: any) => {
      this.items.push(element);
    });
  }
  get patternRules() {
    return [(v: string) => (v && !!v.trim()) || this.$i18n.t('pattern_required_message')];
  }
}
