import { Component, Vue, Prop, Watch, PropSync } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import EditFolderRuleDialog from './edit-folder-rule/edit-folder-rule.vue';

import { Logger } from 'fsts';
import folder, { Folder } from '@/shared/model/folder';
import folderRule, { FolderRule, ActionTypes, ConditionTypes } from '@/shared/model/folderRule';
import { OdataItems } from '@/shared/model/OdataItems';
import { OrganisationDocumentExtraStatusSetting } from '@/shared/model/organisationDocumentExtraStatusSetting';
import treeUtils from '@/shared/utils/treeUtils';
import NumberUtils from '@/shared/utils/NumberUtils';
import { Organisation } from '@/shared/model/organisation';

const name = 'workflow-rules-dialog';
const logger = new Logger(name);
const folderRuleModule = namespace('folderRule');
const organisationDocumentExtraStatusSettingModule = namespace('organisationDocumentExtraStatusSetting');
const folderModule = namespace('folder');
const organisationModule = namespace('organization');

@Component({
  name: name,
  components: { EditFolderRuleDialog },
})
export default class WorkflowRulesDialog extends Vue {
  @folderRuleModule.Getter('getFolderRules')
  private folderRules!: OdataItems<FolderRule>;
  get headers() {
    const headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
    }[] = [
      { text: this.$t('condition'), value: 'conditionType' },
      { text: this.$t('action'), value: 'actionType' },
      { text: this.$t(''), value: 'actions' },
    ];
    return headers;
  }

  @folderRuleModule.Getter('getFolderRule')
  private folderRule!: FolderRule;
  @folderRuleModule.Action('getFolderRules')
  private actionGetFolderRules!: any;
  @folderRuleModule.Action('updateFolderRule')
  private actionUpdateFolderRule!: any;
  @folderRuleModule.Action('deleteFolderRule')
  private actionDeleteFolderRule!: any;

  @organisationDocumentExtraStatusSettingModule.Getter('getOrganisationDocumentExtraStatusSettings')
  private organisationDocumentExtraStatusSettings!: OdataItems<OrganisationDocumentExtraStatusSetting>;

  @organisationModule.Getter('getOrganizationFoldersAll')
  private organizationFolders!: any;
  @organisationModule.Getter('getOrganizations')
  private organisations!: OdataItems<Organisation>;
  @organisationModule.Getter('gobdBlueIsProofed')
  private gobdBlueIsProofed!: any;

  @Prop({ default: false })
  private dialog!: boolean;
  @Watch('dialog')
  onDialog(newV: boolean, oldV: boolean) {
    if (newV) this.actionGetFolderRules({ folderId: this.value.id });
  }
  @Watch('folderRules.searchParams.filter')
  public onOptionsFilterChanged(newVal: any, oldVal: any) {
    logger.debug(`----filter:${oldVal}->${newVal}`);
    this.actionGetFolderRules({ folderId: this.value.id });
  }
  @Watch('folderRules.searchParams.dataOption', { deep: true })
  public onOptionsChanged(newVal: any, oldVal: any) {
    logger.debug(`----onOptionsChanged:${oldVal}->${newVal}`);

    if (JSON.stringify(newVal) != JSON.stringify(oldVal)) {
      this.actionGetFolderRules({ folderId: this.value.id });
    }
  }
  @Prop({ default: () => folder.parse({}) })
  private value!: Folder;

  @Prop({ default: false, type: Boolean })
  ShowDelete!: boolean;

  private onClose() {
    this.$emit('click:close', this.value);
  }

  private async onAdd() {
    this.dialogEditFolderRule.model = {
      ...folderRule.parse({
        folderId: this.value.id,
        organisationId: this.value.organisationId,
      }),
    };
    this.dialogEditFolderRule.isVisible = true;
    this.dialogEditFolderRule.showDelete = false;
  }

  dialogEditFolderRule = {
    isVisible: false,
    model: folderRule.parse({}),
    showDelete: false,
  };
  private onEditFolderRule(item: FolderRule) {
    this.dialogEditFolderRule.model = { ...item };
    this.dialogEditFolderRule.isVisible = true;
    this.dialogEditFolderRule.showDelete = true;
  }
  private onDeleteFolderRule(item: FolderRule) {
    this.$confirm
      .open(`${this.$t('dialog_delete_folder_rule.title')}`, `${this.$t('dialog_delete_folder_rule.message')}`, {
        cancelText: this.$t('dialog_delete_folder_rule.cancel'),
        okText: this.$t('dialog_delete_folder_rule.ok'),
      })
      .then(async (response: any) => {
        if (response) {
          await this.actionDeleteFolderRule(item.id).then(async () => {
            await this.actionGetFolderRules({ folderId: this.value.id });
            this.value.folderRulesCount = this.folderRules.items.length;
          });
        }
      });
  }
  async dialogEditFolderRuleOnUpdate(item: FolderRule) {
    await this.actionUpdateFolderRule(item);
    await this.actionGetFolderRules({ folderId: this.value.id });
    this.value.folderRulesCount = this.folderRules.items.length;
    this.dialogEditFolderRule.isVisible = false;
  }
  async dialogEditFolderRuleOnDelete(item: FolderRule) {
    await this.actionDeleteFolderRule(item.id);
    await this.actionGetFolderRules({ folderId: this.value.id });
    this.value.folderRulesCount = this.folderRules.items.length;
    this.dialogEditFolderRule.isVisible = false;
  }
  dialogEditFolderRuleOnClose() {
    this.dialogEditFolderRule.isVisible = false;
  }
  @folderModule.Getter('getMenuFoldersTreeViewFlat')
  private foldersFlat!: Folder[];
  @organisationDocumentExtraStatusSettingModule.Getter('getNameByValue')
  private getNameByValue!: any;

  getFullPath(item: FolderRule) {
    if (item.actionType == 'change_status') return this.getActionStatusesJoinedWithTranslation(item);
    if (item.actionMoveToFolderId == '')
      return `${this.$t('pattern', {
        0: item.actionMoveToFolderPattern,
        pattern_type: this.$t(`pattern_types.${item.actionMoveToFolderPatternType}`).toString(),
      })}`;
    if (item.copyToOrganisationId == '') return treeUtils.getFolderPath(item.actionMoveToFolderId, this.foldersFlat);
    else return treeUtils.getFolderPath(item.actionMoveToFolderId, this.organizationFolders.treeViewFlat);
  }
  private getActionStatusesJoinedWithTranslation(item: FolderRule) {
    const b_translate = (x?: boolean) => this.$t(`change_status_${x}`).toString();
    return item.actionOptions.status
      ?.map((x: { active?: boolean; value: string }) => `"${this.getNameByValue(x.value)}=${b_translate(x.active)}"`)
      .join(` ${this.$t('and')} `);
  }

  getOrganizationName(item: FolderRule): string {
    if (item.copyToOrganisationId == '') return ``;
    return this.organisations.items?.find((x) => x.organisationId == item.copyToOrganisationId)?.name ?? '';
  }
  calcStatusHint(item: FolderRule) {
    //TODO move to backend to enable filtering
    let statusHint = '';
    if (item.conditionType == ConditionTypes.has_status) {
      if (item.conditionStatus) {
        const statuses = item.conditionStatus.split(';');
        if (statuses.includes('allocated')) statusHint += ` "${this.$t('has_allocation')}" ${this.$t('and')}`;
        if (statuses.includes('hasgobd'))
          statusHint += ` "${this.$t('has_gobd', {
            gobdBlueIsProofed: this.gobdBlueIsProofed ? this.$t('blue') : this.$t('green'),
          })}" ${this.$t('and')}`;
        this.organisationDocumentExtraStatusSettings.items.forEach((element) => {
          if (statuses.indexOf(element.statusValue) > -1) statusHint += ` "${element.name}" ${this.$t('and')}`;
        });
        statusHint = statusHint.substring(0, statusHint.length - (this.$t('and') + '').length);
      } else statusHint = ` ""`;
    }
    return statusHint;
  }
  calcConditionAmountMin(item: FolderRule) {
    return NumberUtils.addZeroes('' + item.conditionAmountMin, this.$root.$i18n.locale.toLocaleLowerCase());
  }
  calcConditionAmountMax(item: FolderRule) {
    return NumberUtils.addZeroes('' + item.conditionAmountMax, this.$root.$i18n.locale.toLocaleLowerCase());
  }
  isFullPathVisible(actionType: ActionTypes): boolean {
    switch (actionType) {
      case ActionTypes.copy_to_organization:
      case ActionTypes.move_to_folder:
        return true;
      default:
        return false;
    }
  }
}
