import { Component, Vue, Prop, Watch, PropSync } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { Logger } from 'fsts';
import { FolderRule } from '@/shared/model/folderRule';

const name = 'action-change-status';
const logger = new Logger(name);
const organisationDocumentExtraStatusSettingModule = namespace('organisationDocumentExtraStatusSetting');

@Component({
  name: name,
  components: {},
})
export default class ActionChangeStatus extends Vue {
  @organisationDocumentExtraStatusSettingModule.Getter('getOrganisationDocumentExtraStatusSettings')
  private getOrganisationDocumentExtraStatusSettings!: any;
  @organisationDocumentExtraStatusSettingModule.Getter('getNameByValue')
  private getNameByValue!: any;
  @organisationDocumentExtraStatusSettingModule.Action('getOrganisationDocumentExtraStatusSettings')
  private actionGetOrganisationDocumentExtraStatusSettings!: any;

  @PropSync('value')
  private model!: FolderRule;
  private selected: any = {};
  @Watch('model', { deep: true })
  onModel(newV: string, oldV: any) {
    this.refreshActualStatuses();
  }
  input() {
    this.model.actionOptions.status = this.getStatesWithout();
  }
  getStatesWithout(active: string = 'undefined'): { value: string; active?: boolean | undefined }[] {
    return this.actualStatuses
      .filter((x: any) => x.selected.active !== active)
      .map((x: any) => {
        return { value: x.selected.value, active: x.selected.active };
      });
  }
  statusesForEdit = [
    'payed',
    'accepted',
    'final_check',
    'extra1',
    'extra2',
    'extra3',
    'extra4',
    'extra5',
    'extra6',
    'extra7',
    'extra8',
    'extra9',
    'extra10',
    'extra11',
    'extra12',
  ];
  actualStatuses: any = this.statusesForEdit.map((x, i) => {
    return { variants: this.getVariant(x), selected: { value: x, active: 'undefined' } };
  });
  getActualStatus(i: number) {
    return this.actualStatuses[i].active;
  }
  mounted() {
    if (!this.getOrganisationDocumentExtraStatusSettings?.total)
      this.actionGetOrganisationDocumentExtraStatusSettings().then(() => {
        this.refreshActualStatuses();
      });
    else this.refreshActualStatuses();
  }

  private refreshActualStatuses() {
    this.getOrganisationDocumentExtraStatusSettings.items.forEach((element: any, index: number) => {
      const existStatus:
        | { value: string; active?: boolean; text?: string }
        | undefined = this.model.actionOptions.status?.filter(
        (x: any) => x?.value.toLowerCase() == element.statusValue.toLowerCase()
      )[0];
      this.actualStatuses[index].selected.active = `${existStatus?.active}`;
    });
  }
  getVariant(id: string): { value: string; active: string; text: string }[] {
    return [
      { value: id, active: 'undefined', text: this.getText('undefined') },
      { value: id, active: 'true', text: this.getText('true') },
      { value: id, active: 'false', text: this.getText('false') },
    ];
  }
  private getText(x: any) {
    if (x === undefined || x == 'undefined') return `${this.$t('empty')}`;
    if (x == 'true') return `${this.$t('yes')}`;
    if (x == 'false') return `${this.$t('no')}`;
    return `${this.$t('empty')}`;
  }
}
