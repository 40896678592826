import { Component, Vue, Prop, Watch, PropSync, Ref } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { Logger } from 'fsts';
import folder, { Folder } from '@/shared/model/folder';

const logger = new Logger('edit-folder-dialog');
const emailDomain = process.env.VUE_APP_EMAIL_DOMAIN;

@Component({
  name: 'edit-folder-dialog',
  components: {},
})
export default class EditFolderDialog extends Vue {
  @Ref('edit-folder-form')
  private refEditFolderForm!: any;

  @Prop({ default: false })
  public isLoading!: boolean;
  @Prop({ default: false, type: Boolean })
  private isCopy!: boolean;
  @Prop({ default: false })
  private dialog!: boolean;
  @Prop({ default: 'add' })
  private mode!: 'add' | 'edit' | 'copy';

  @Prop({ default: () => folder.parse({}) })
  private value!: any;
  @Watch('value')
  onValue(nVal: any, oVal: any) {
    this.resetValidation();
    this.resetNameOnAddToAvoidValidationError();
    if (nVal.parent) {
      this.selected = [nVal.parent];
    } else {
      this.selected = [this.empty];
    }
  }

  // (ED-984) Reset name for `ADD` mode to not get `required folder name` validation message on open (validation `reset` method not work for some reason)
  resetNameOnAddToAvoidValidationError() {
    if (this.mode === 'add') {
      this.value.name = null;
    }
  }

  @Prop({ default: () => [] })
  parents!: {
    folder: Folder;
    id: string;
    name: string;
    parent: { name: string; id: string };
    children: any[];
  }[];

  @Prop({ default: false, type: Boolean })
  ShowDelete!: boolean;

  private isFormValid = true;
  get nameRules() {
    return [
      (v: string) => (v && !!v.trim()) || this.$i18n.t('name_required_message'), // i18n.tc(`name_required_message`) is not working here, just shows `name_required_message` key
      (v: string) =>
        (v && !!v.trim() && !this.nameRepeatOnSameLevel(v.trim())) || this.$i18n.t('name_repeated_on_same_level'),
    ];
  }
  nameRepeatOnSameLevel(newName: string) {
    let children: any = undefined;
    if (this.selected[0] !== undefined && this.selected[0]?.id != '') {
      children = this.selected[0].children?.find(
        (x: { name: string; id: string }) => x.name == newName && x.id != this.value.id
      );
    } else {
      children = this.items.find((x) => x.name == newName && x.id != this.value.id);
    }
    return children !== undefined;
  }
  get title() {
    return this.$i18n.t('title_' + this.mode);
  }

  resetValidation() {
    this.refEditFolderForm?.resetValidation();
  }

  private onDelete() {
    this.$emit('click:delete', this.value);
  }
  private onClose() {
    this.resetValidation();
    this.$emit('click:close', this.value);
  }

  private async onCopy() {
    const isFormValidResult = await this.refEditFolderForm.validate();

    if (!isFormValidResult) return;
    this.$emit('click:copy', { from: this.value, to: this.selected[0], toName: this.value.name });
  }
  private async onConfirm() {
    const isFormValidResult = await this.refEditFolderForm.validate();

    if (isFormValidResult) {
      if (this.value) this.value.giveAllRights = this.value.giveAllRights;
      this.checkIdForEditMode();
      this.value.parentFolderId = this.selected[0].id;
      if (this.mode == 'edit') this.$emit('click:update', this.value, this.selected[0]);
      if (this.mode == 'add') this.$emit('click:add', this.value, this.selected[0]);
      if (this.mode == 'copy') this.$emit('click:copy', this.value, this.selected[0]);
    }
  }
  // (ED-984) sometimes `id` for `this.value.folder` is empty so instead of editing !!! **NEW folder is created**
  checkIdForEditMode() {
    if (this.mode == 'edit' && !this.value.id) {
      this.value.id = this.value.id;
    }
  }

  get selectedName() {
    let selectedName = '';
    if (this.selected && this.selected.length > 0 && this.selected[0]) selectedName = this.selected[0].name;
    return selectedName;
  }
  markAsSelected: any = '';
  menu = false;
  selected: any[] = [];
  get empty() {
    return {
      id: '',
      name: `${this.$t('no_folder')}`,
      parent: undefined,
      folder: undefined,
      children: undefined,
      fullPath: `${this.$t('no_folder')}`,
    };
  }

  private getMailTo(item: { folder: Folder; id: string; name: string }): string {
    const mailto = item.folder?.folderIdOld || item.id + '@' + emailDomain;
    return mailto;
  }
  @Watch('dialog')
  onDialogChange(newV: boolean) {
    this.initParentsList();
    if (newV) {
      if (this.value.color) {
        this.color = this.value.color;
      }
    }
  }
  @Watch('parents')
  onParentsChange(newV: any) {
    this.initParentsList();
  }
  color = '#7B7B7B';
  private initParentsList() {
    this.items.length = 0;
    this.items.push(this.empty);
    this.parents.forEach((element) => {
      this.items.push(element);
    });
  }

  get rules() {
    return {
      hexa: (value: string) => {
        const pattern = /^#[0-9A-F]{6}[0-9a-f]{0,2}$/i;
        return value.length == 0 || pattern.test(value) || this.$t('invalid_color');
      },
    };
  }
  items: {
    folder?: Folder;
    id: string;
    name: string;
    parent?: { name: string; id: string };
    children?: any[];
  }[] = [];
  @Watch('menu')
  private async onMenuStateUpdate(nVal: boolean, oVal: boolean) {
    if (nVal) {
      this.resetValidation();
    } else await this.refEditFolderForm.validate();
  }
  @Watch('selected', { deep: true })
  private onSelected(nVal: any, oVal: any) {
    if (nVal != oVal) this.menu = false;
  }
  async created() {}
  swatches = [
    ['#ffffff'],
    ['#f8b043'],
    ['#eeee22'],
    ['#81d742'],
    ['#7fb8eb'],
    ['#bf7a2b'],
    ['#f75353'],
    ['#b77af5'],
    ['#f484f9'],
  ];
}
