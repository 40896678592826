import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import folder, { Folder, Value } from '@/shared/model/folder';
import AddEditDialog from './edit-folder/edit-folder.vue';
import DeleteDialog from './delete-folder/delete-folder.vue';
import WorkflowRulesDialog from './workflow-rules/workflow-rules.vue';
import TreeUtils from '@/shared/utils/treeUtils';
import { Organisation } from '@/shared/model/organisation';

const name = 'folder-settings-view';
const logger = new Logger(name);
const folderModule = namespace('folder');
const organisationModule = namespace('organization');
const emailDomain = process.env.VUE_APP_EMAIL_DOMAIN;

@Component({ name: name, components: { AddEditDialog, DeleteDialog, WorkflowRulesDialog } })
export default class FolderSettingsView extends Vue {
  @folderModule.Action('getMenuFolders')
  private getMenuFolders!: any;
  @folderModule.Action('getFolder')
  private actionGetFolder!: any;
  @folderModule.Action('copyFolder')
  private actionCopyFolder!: any;
  @folderModule.Action('deleteFolder')
  private actionDeleteFolder!: any;
  @folderModule.Getter('getMenuFoldersTreeView')
  private folders!: {
    folder: Folder;
    id: string;
    name: string;
    parent: { name: string; id: string };
    count: undefined;
    children: any[];
    isBuiltIn: boolean;
  }[];
  private open: string[] = [];
  @folderModule.Getter('getInProgressUpdateFolder')
  private inProgressUpdateFolder!: boolean;
  @organisationModule.Getter('getOrganization')
  private organization!: Organisation;
  @organisationModule.Getter('isFolderOpenTypeOpenTheWholeStructure')
  private isFolderOpenTypeOpenTheWholeStructure!: Organisation;
  @organisationModule.Action('updateFolderOpenType')
  private updateFolderOpenType!: any;

  async mounted() {
    logger.log('mounted');
  }
  dialogDelete = {
    show: false,
    model: {
      id: '',
      name: '',
      fullPath: '',
      parent: { name: '', id: '' },
      children: [{}],
    },
  };

  //#region add|edit
  @folderModule.Action('updateFolder')
  private actionUpdateFolder!: any;
  dialogAddEdit: {
    show: boolean;
    model: Folder & { parent?: Folder };
    editModel: { disabled: boolean };
    copyFromId: string;
    showDelete: boolean;
    isCopy: boolean;
    mode: 'add' | 'edit' | 'copy';
  } = {
    show: false,
    model: { ...folder.parse({}) },
    editModel: { disabled: false },
    copyFromId: '',
    showDelete: false,
    isCopy: false,
    mode: 'add',
  };
  async dialogAddEditOnClose(folder: any) {
    folder.disabled = false;
    this.dialogAddEdit.editModel.disabled = false;
    this.dialogAddEdit.showDelete = false;

    this.dialogAddEdit.show = false;
    this.dialogAddEdit.isCopy = false;
  }
  openLeaf(folder: any, open: boolean): void {
    if (!open && this.isFolderOpenTypeOpenTheWholeStructure)
      TreeUtils.openFoldersAndChildrens(folder, folder.children, this.open);
  }
  async dialogAddEditOnUpdate(item: any, newParent: any) {
    //remove disabled property from edited element
    // this.dialogAddEdit.editModel = Object.assign(this.dialogAddEdit.editModel, { name: item.name, disabled: false });
    item.disabled = false;

    const result = await this.actionUpdateFolder(item).finally(async () => {
      await this.getMenuFolders();
    });

    this.dialogAddEdit.showDelete = false;
    this.dialogAddEdit.show = false;
  }

  async dialogAddEditOnAdd(item: any, newParent: any) {
    await this.actionUpdateFolder(item).catch(async () => {});
    // update item id
    await this.getMenuFolders();
    this.openFolderParent(newParent);

    item.disabled = false;
    this.dialogAddEdit.show = false;
    this.dialogAddEdit.showDelete = false;
  }

  openFolderParent(parent: any) {
    if (parent?.id) {
      this.open.push(parent.id); // (ED-1227) tested: duplicates are not cretated if multiple folders for 1 paren was added
    }
  }

  //sorting folders with key isBuiltIn for upper element

  async dialogAddEditOnCopy(x: { from: any; to: any; toName: string }) {
    const result = await this.actionCopyFolder({
      fromId: this.dialogAddEdit.copyFromId,
      toId: x.to.id,
      toName: x.toName,
    });
    //create new item as clone 'from' folder
    await this.getMenuFolders();

    this.dialogAddEdit.showDelete = false;
    this.dialogAddEdit.isCopy = false;

    this.dialogAddEdit.show = false;
  }
  async dialogAddEditOnDelete(item: {
    folder: Folder;
    id: string;
    name: string;
    fullPath: string;
    parent: { name: string; id: string };
    children: any[];
  }) {
    if (!item.parent) {
      const x = this.folders.find((x) => x.isBuiltIn == true);
      item.parent = { id: x?.id || '', name: x?.name || '' };
    }
    this.dialogDelete.model = item;
    this.dialogDelete.show = true;
  }
  async dialogDeleteOnDelete(item: {
    id: string;
    name: string;
    fullPath: string;
    parent: { name: string; id: string; children?: any[] };
    children: any[];
    parentFolderId: string;
  }) {
    await this.actionDeleteFolder({ id: item.id, moveToId: item.parent.id })
      .then(async () => {
        // if removed from subdirectories
        await this.getMenuFolders();
      })
      .catch((err: any) => {
        logger.error(err);
      });
    this.dialogDelete.show = false;
    this.dialogAddEdit.show = false;
  }
  async dialogDeleteOnClose(folder: any) {
    folder.disabled = false;

    this.dialogDelete.show = false;
  }

  canCreateSubfoldersFor(item: {
    folder: Folder;
    id: string;
    name: string;
    fullPath: string;
    isBuiltIn: boolean;
  }): boolean {
    // max subfolder lvl is 4 && not builtin folder
    return item.fullPath.split('/').length < 5 && !item.isBuiltIn;
  }
  canCopyFolder(item: { folder: Folder; id: string; name: string; fullPath: string; isBuiltIn: boolean }): boolean {
    // not builtin folder
    return !item.isBuiltIn;
  }

  private async onCopyTo(item: {
    folder: Folder;
    id: string;
    name: string;
    fullPath: string;
    parent: Folder;
    children: any[];
  }) {
    this.dialogAddEdit.model = Object.assign(folder.parse({}), {
      name: '',
      parentFolderId: item.parent?.id,
      parent: item.parent,
      fullPath: item.parent?.fullPath,
      children: undefined,
    });
    this.dialogAddEdit.copyFromId = item.id;
    this.dialogAddEdit.isCopy = true;
    this.dialogAddEdit.mode = 'copy';
    this.dialogAddEdit.show = true;
  }
  private async onAdd(item: {
    folder: Folder;
    id: string;
    name: string;
    fullPath: string;
    parent: Folder;
    children: any[];
  }) {
    this.dialogAddEdit.model = Object.assign(folder.parse({}), {
      parentFolderId: item.id,
      parent: folder.parse(item),
      folder: folder.parse({}),
      fullPath: item.fullPath,
      children: undefined,
    });
    this.dialogAddEdit.showDelete = false;
    this.dialogAddEdit.mode = 'add';
    this.dialogAddEdit.show = true;
  }
  private async onEdit(folder: any) {
    folder.disabled = true;
    this.dialogAddEdit.showDelete = true;
    this.dialogAddEdit.model = { ...folder };
    this.dialogAddEdit.model.parent = TreeUtils.findParrentByFullPathInTreeView(folder, this.folders);
    this.dialogAddEdit.editModel = folder;
    this.dialogAddEdit.mode = 'edit';
    this.dialogAddEdit.show = true;
  }
  private getMailTo(item: { folder: Folder; id: string; name: string; folderIdOld: string }): string {
    const mailto = item.folderIdOld || item.id + '@' + emailDomain;
    return mailto;
  }

  dialogWorkflowRules = {
    isVisible: false,
    model: {},
  };
  private onWorkflowRules(item: { folder: Folder; id: string; name: string }) {
    this.dialogWorkflowRules.model = item;
    this.dialogWorkflowRules.isVisible = true;
  }
  dialogWorkflowRulesOnConfirm() {}
  dialogWorkflowRulesOnClose() {
    this.dialogWorkflowRules.isVisible = false;
  }
}
