import { Component, Vue, Prop, Watch, PropSync, Ref } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { Logger } from 'fsts';
import folderRule, { ActionTypes, ConditionTypes, FolderRule } from '@/shared/model/folderRule';
import ConditionHaveStatus from './condition-have-status/condition-have-status.vue';
import ActionMoveToFolder from './action-move-to-folder/action-move-to-folder.vue';
import ActionCopyToOrganization from './action-copy-to-organization/action-copy-to-organization.vue';
import ActionSendEmail from './action-send-email/action-send-email.vue';
import ActionChangeStatus from './action-change-status/action-change-status.vue';
import { Folder } from '@/shared/model/folder';
import NumberUtils from '@/shared/utils/NumberUtils';
import { OdataItems } from '@/shared/model/OdataItems';
import { Organisation } from '@/shared/model/organisation';

const name = 'edit-workflow-rule-dialog';
const logger = new Logger(name);
const emailDomain = process.env.VUE_APP_EMAIL_DOMAIN;
const organizationModule = namespace('organization');

@Component({
  name: name,
  components: {
    ConditionHaveStatus,
    ActionMoveToFolder,
    ActionSendEmail,
    ActionCopyToOrganization,
    ActionChangeStatus,
  },
})
export default class EditFolderRuleDialog extends Vue {
  @Ref('edit-folder-rule-form')
  private refEditFolderForm!: any;
  private isFormValid: boolean = true;
  @Prop({ default: '' })
  private title!: string;

  @Prop({ default: false })
  private dialog!: boolean;
  @organizationModule.Getter('getOrganizations')
  private organizations!: OdataItems<Organisation>;

  @Prop({ default: () => folderRule.parse({}) })
  private value!: FolderRule;
  selectedFolders: any[] = [];

  @Watch('dialog')
  async onDialog(newV: boolean) {
    if (newV === true) {
      this.initLocalValues(this.value);
      this.refEditFolderForm?.resetValidation();
    }
  }
  // @Watch('value')
  onConditionType(newV: any) {
    switch (this.value.conditionType) {
      case ConditionTypes.amount_between:
        break;
      case ConditionTypes.amount_greater_than:
        this.value.conditionAmountMax = 0;
        break;
      case ConditionTypes.amount_lower_than:
        this.value.conditionAmountMin = 0;
        break;
      default:
        this.value.conditionAmountMin = 0;
        this.value.conditionAmountMax = 0;
        break;
    }
    this.initLocalValues(this.value);
  }

  conditionAmountMin: string = '';
  conditionAmountMax: string = '';
  get locale() {
    return this.$root.$i18n.locale.toLowerCase();
  }
  initLocalValues(value: FolderRule) {
    if (this.value.actionMoveToFolder) {
      this.selectedFolders = Object.assign(this.selectedFolders, [
        { name: value.actionMoveToFolder.fullPath, id: value.actionMoveToFolderId },
      ]);
    }
    this.conditionAmountMin = NumberUtils.addZeroes(value.conditionAmountMin + '', this.locale);
    this.conditionAmountMax = NumberUtils.addZeroes(value.conditionAmountMax + '', this.locale);
  }

  onBlurConditionAmountMin() {
    // for correction typo errors with decimal separator
    const localizedAfterUserEnter = NumberUtils.toLocal(this.conditionAmountMin, this.locale);
    this.value.conditionAmountMin = NumberUtils.toApi(localizedAfterUserEnter, this.locale);
    this.initLocalValues(this.value);
  }
  onBlurConditionAmountMax() {
    // for correction typo errors with decimal separator
    const localizedAfterUserEnter = NumberUtils.toLocal(this.conditionAmountMax, this.locale);
    this.value.conditionAmountMax = NumberUtils.toApi(localizedAfterUserEnter, this.locale);
    this.initLocalValues(this.value);
  }

  @Prop({ default: false, type: Boolean })
  ShowDelete!: boolean;

  private onDelete() {
    this.$emit('click:delete', this.value);
  }

  private onClose() {
    this.$emit('click:close', this.value);
  }

  private async onConfirm() {
    if (!this.refEditFolderForm.validate()) return;
    this.$emit('click:update', this.value);
  }

  get conditionTypes() {
    return [
      { id: 'has_status', name: this.$t('has_status') },
      { id: 'new_document', name: this.$t('new_document') },
      //TODO on import check [Condition][Type]=has_allocation and [Condition][Type]: has_status , [Status]: allocated
      // { id: 'has_allocation', name: this.$t('has_allocation') },
      //TODO on import check [Condition][Type]: has_gobd moved as [Status]: godb
      //{ id: 'has_gobd', name: this.$t('has_gobd') },
      { id: 'amount_greater_than', name: this.$t('amount_greater_than') },
      { id: 'amount_lower_than', name: this.$t('amount_lower_than') },
      { id: 'amount_between', name: this.$t('amount_between') },
      { id: 'days_in_folder', name: this.$t('days_in_folder') },
    ];
  }
  get actionTypes() {
    const actionTypes = [];
    actionTypes.push({ id: ActionTypes.attach_address, name: this.$t('attach_address') });
    if (this.organizations.items.length > 1)
      actionTypes.push({ id: ActionTypes.copy_to_organization, name: this.$t('copy_to_organization') });
    actionTypes.push({ id: ActionTypes.move_to_folder, name: this.$t('move_to_folder') });
    actionTypes.push({ id: ActionTypes.send_email, name: this.$t('send_email') });
    if (this.value.conditionType == 'days_in_folder')
      actionTypes.push({ id: ActionTypes.delete, name: this.$t('delete') });
    actionTypes.push({ id: ActionTypes.change_status, name: this.$t('change_status') });
    return actionTypes;
  }
  onActionType(actionType: ActionTypes) {
    if (this.value.actionOptions.status) {
      if (actionType != ActionTypes.change_status) {
        this.resetActionStatus();
      }
    }
    if (actionType != ActionTypes.copy_to_organization && actionType != ActionTypes.move_to_folder) {
      this.value.copyToOrganisationId = '';
      this.value.actionMoveToFolderId = '';
      this.selectedFolders = [];
    }
  }
  resetActionStatus() {
    this.value.actionOptions.status = undefined;
  }
  async mounted() {
    this.initLocalValues(this.value);
  }
}
