import { render, staticRenderFns } from "./action-copy-to-organization.html?vue&type=template&id=6bbf682c&scoped=true&external"
import script from "./action-copy-to-organization.ts?vue&type=script&lang=ts&external"
export * from "./action-copy-to-organization.ts?vue&type=script&lang=ts&external"
import style0 from "./action-copy-to-organization.scss?vue&type=style&index=0&id=6bbf682c&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bbf682c",
  null
  
)

/* custom blocks */
import block0 from "./action-copy-to-organization.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fviews%2Fsettings%2Fcompany%2Ffolder%2Fworkflow-rules%2Fedit-folder-rule%2Faction-copy-to-organization%2Faction-copy-to-organization.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VBtn,VCard,VCardText,VCol,VIcon,VMenu,VRow,VSelect,VSpacer,VTextField,VTreeview})
