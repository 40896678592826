import { Component, Vue, Prop, Watch, PropSync } from 'vue-property-decorator';
import { Logger } from 'fsts';
import folder, { Folder } from '@/shared/model/folder';

const name = 'delete-folder-dialog';
const logger = new Logger(name);

@Component({
  name: name,
  components: {},
})
export default class DeleteFolderDialog extends Vue {
  @Prop({ default: false })
  private dialog!: boolean;

  @Prop({ default: () => folder.parse({}) })
  private value!: any;
  @Watch('value')
  async onValue(nVal: any, oVal: any) {
    this.selected = [nVal?.parent];
    this.disabled = [nVal];
  }
  disabled: any[] = [];
  @Prop({ default: () => [] })
  parents!: {
    id: string;
    name: string;
    parent: { name: string; id: string };
    children: any[];
  }[];

  private onClose() {
    this.$emit('click:close', this.selected[0]);
  }

  private async onDelete() {
    this.value.parent = this.selected[0];
    this.$emit('click:delete', this.value);
  }
  get selectedName() {
    let selectedName = '';
    if (this.selected && this.selected.length > 0 && this.selected[0]) selectedName = this.selected[0].name;
    return selectedName;
  }
  markAsSelected: any = '';
  menu = false;
  selected: any[] = [];
  getFullPath(item: any) {
    // copy path from parrent names
    let parent = item.parent;
    let fullPath = item.name;
    do {
      fullPath = parent?.id ? ` ${parent.name} ${'/ '}  ${fullPath} ` : ` ${fullPath} `;

      parent = parent?.parent;
    } while (parent?.id);
    return fullPath;
  }
  @Watch('dialog')
  onParents(newV: boolean) {
    this.items.length = 0;
    this.parents.forEach((element) => {
      this.items.push(element);
    });
  }

  items: {
    folder?: Folder;
    id: string;
    name: string;
    parent?: { name: string; id: string };
    children?: any[];
  }[] = [];

  @Watch('selected', { deep: true })
  private onSelected(nVal: any, oVal: any) {
    if (nVal != oVal) this.menu = false;
  }
}
