import { Component, Vue, Prop, Watch, PropSync } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { Logger } from 'fsts';
import { DocumentStatusValues } from '@/shared/model/documentStatus';

const name = 'condition-have-status';
const logger = new Logger(name);
const organisationDocumentExtraStatusSettingModule = namespace('organisationDocumentExtraStatusSetting');
const organisationModule = namespace('organization');

@Component({
  name: name,
  components: {},
})
export default class ConditionHaveStatus extends Vue {
  @organisationModule.Getter('gobdBlueIsProofed')
  private gobdBlueIsProofed!: any;
  @organisationDocumentExtraStatusSettingModule.Getter('getOrganisationDocumentExtraStatusSettings')
  private getOrganisationDocumentExtraStatusSettings!: any;
  @organisationDocumentExtraStatusSettingModule.Action('getOrganisationDocumentExtraStatusSettings')
  private actionGetOrganisationDocumentExtraStatusSettings!: any;
  extraStatuses = { gobd: DocumentStatusValues.Hasgobd, allocated: DocumentStatusValues.Allocated };
  @PropSync('value')
  private model!: string;
  private selected: string[] = [];
  @Watch('selected', { deep: true })
  onSelected(newV: string[], oldV: any) {
    this.model = newV.join(';');
  }
  @Watch('model', { deep: true })
  onModel(newV: string, oldV: any) {
    this.selected = this.model ? newV.split(';') : [];
  }
  mounted() {
    //backend store data as 'payed2;accepted2' so we need split them and join on save
    this.selected = this.model ? this.model.split(';') : [];
    if (!this.getOrganisationDocumentExtraStatusSettings?.total)
      this.actionGetOrganisationDocumentExtraStatusSettings();
  }
}
